<template>
  <footer class="l-footer--sticky p-strip--suru-topped">
    <div class="row">
      <div class="col-5 social">
        <a class="linkedin p-tooltip--top-center" href="https://linkedin.com/in/sebastianhagens" target="_blank" title="LinkedIn">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-linkedin" viewBox="0 0 16 16">
            <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z"/>
          </svg>
          <span class="p-tooltip__message" role="tooltip" id="tp-rght">LinkedIn</span>
        </a>
        <a class="twitter p-tooltip--top-center" href="https://www.twitter.com/sebastix" target="_blank" title="Twitter">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-twitter" viewBox="0 0 16 16">
            <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z"/>
          </svg>
          <span class="p-tooltip__message" role="tooltip" id="tp-rght">Twitter</span>
        </a>
        <a class="gitlab p-tooltip--top-center" href="https://gitlab.com/sebastix" target="_blank" title="Gitlab">
          <svg width="24" height="24" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg"><path d="m0 0h100v100h-100z" fill="none"></path><path d="m99.82 56.61l-5.61-17.21-11.08-34.15a1.92 1.92 0 0 0-3.63 0l-11.09 34.13h-36.83l-11.08-34.13a1.92 1.92 0 0 0-3.63 0l-11.07 34.13-5.6 17.23a3.82 3.82 0 0 0 1.38 4.26l48.42 35.19 48.42-35.18a3.83 3.83 0 0 0 1.4-4.26" fill="#ec6d2e"></path><path d="m50 96h0l18.42-56.63h-36.82z" fill="#9c9b9b"></path><path d="m50 96l-18.42-56.63h-25.78z" fill="#c6c6c5"></path><path d="m5.79 39.39h0l-5.6 17.22a3.82 3.82 0 0 0 1.38 4.26l48.43 35.19z" fill="#ececec"></path><path d="m5.8 39.39h25.8l-11.1-34.12a1.92 1.92 0 0 0-3.63 0z" fill="#9c9b9b"></path><path d="m50 96l18.42-56.63h25.8z" fill="#c6c6c5"></path><path d="m94.21 39.39h0l5.6 17.22a3.82 3.82 0 0 1-1.38 4.26l-48.43 35.13 44.2-56.64z" fill="#ececec"></path><path d="m94.22 39.39h-25.8l11.08-34.12a1.92 1.92 0 0 1 3.63 0z" fill="#9c9b9b"></path></svg>
          <span class="p-tooltip__message" role="tooltip" id="tp-rght">Gitlab</span>
        </a>
        <a class="github p-tooltip--top-center" href="https://github.com/sebastix" target="_blank" title="Github">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-github" viewBox="0 0 16 16">
            <path d="M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27.68 0 1.36.09 2 .27 1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38A8.012 8.012 0 0 0 16 8c0-4.42-3.58-8-8-8z"/>
          </svg>
          <span class="p-tooltip__message" role="tooltip" id="tp-rght">Github</span>
        </a>
        <a class="drupal p-tooltip--top-center" href="https://www.drupal.org/u/sebastian-hagens" target="_blank" title="Drupal">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="24" viewBox="0 0 277.73084 362.8816"><title>Risorsa 24</title><g id="Livello_2" data-name="Livello 2"><g id="Livello_1-2" data-name="Livello 1"><path d="M196.00843,77.29177C170.47408,51.76951,146.11187,27.43962,138.86135,0c-7.25088,27.43962-31.617,51.76951-57.14709,77.29177C43.41893,115.56293,0,158.93748,0,223.99184,0,300.57855,62.291,362.8816,138.86135,362.8816c76.58243,0,138.86524-62.29879,138.86949-138.88976,0-65.05011-43.41537-108.42891-81.72241-146.70007M59.02214,256.34977c-8.51464-.28912-39.93878-54.453,18.35806-112.124L115.95754,186.365s2.36878,2.22706-.25751,4.92082c-9.2055,9.44134-48.44171,48.78732-53.31849,62.39221-1.0066,2.80815-2.47674,2.70194-3.3594,2.67175m79.84347,71.38635a47.759,47.759,0,0,1-47.75939-47.75938c0-12.09214,4.80716-22.86793,11.90389-31.54632,8.61161-10.53036,35.8491-40.148,35.8491-40.148s26.8205,30.05237,35.78481,40.04746a46.706,46.706,0,0,1,11.981,31.64684,47.75949,47.75949,0,0,1-47.75937,47.75938m91.41133-77.44876c-1.02935,2.25121-3.36439,6.00948-6.516,6.12421-5.6177.2046-6.218-2.67388-10.37017-8.819-9.116-13.49017-88.67067-96.63406-103.5507-112.71395-13.08844-14.143-1.84309-24.11392,3.37325-29.33914,6.54441-6.55613,25.6473-25.64732,25.6473-25.64732s56.96026,54.04379,80.68775,90.97055,15.55027,68.88013,10.72856,79.42469" style="fill:#fff"/></g></g></svg>
          <span class="p-tooltip__message" role="tooltip" id="tp-rght">Drupal</span>
        </a>
        <a class="stackoverflow p-tooltip--top-center" href="https://stackoverflow.com/users/1269858/sebastian-hagens" target="_blank" title="stackoverflow">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 120 120">
            <path class="st0" fill="#FFF" d="M22.4 57.5h74.8v15.4H22.4z"/>
            <path class="st1" d="M22.4 37.6h74.8V53H22.4z"/>
            <path class="st2" d="M85.5 17H34.4c-6.6 0-12 5.5-12 12.3v4h74.8v-4C97.2 22.5 92 17 85.5 17z"/>
            <path class="st3" d="M22.4 77.3v4c0 6.8 5.4 12.3 12 12.3h32v16.3l15.8-16.3h3.5c6.6 0 12-5.5 12-12.3v-4H22.4z" />
          </svg>
          <span class="p-tooltip__message" role="tooltip" id="tp-rght">StackOverflow</span>
        </a>
      </div>
      <div class="col-7 contact">
        <div class="row u-vertically-center">
          <div class="col-4 mail">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-chat-left-text" viewBox="0 0 16 16">
              <path d="M14 1a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H4.414A2 2 0 0 0 3 11.586l-2 2V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12.793a.5.5 0 0 0 .854.353l2.853-2.853A1 1 0 0 1 4.414 12H14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
              <path d="M3 3.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zM3 6a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9A.5.5 0 0 1 3 6zm0 2.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5z"/>
            </svg>
            <span>info@sebastix.nl</span>
          </div>
          <div class="col-3 phone">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-phone" viewBox="0 0 16 16">
              <path d="M11 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h6zM5 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H5z"/>
              <path d="M8 14a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
            </svg>
            <span>+316 4171 2778</span>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer"
}
</script>

<style scoped lang="scss">
  footer {
    line-height: 50px;
    background-color: var(--backgroundColor);

    &.p-strip--suru-topped {
      background-blend-mode: normal, normal, normal, normal;
      background-image: linear-gradient(to top left, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.2) 49%, transparent 50%, transparent 100%), linear-gradient(to top left, rgba(245, 124, 49, 0.5) 0%, rgba(245, 124, 49, 0.2) 49%, transparent 50%, transparent 100%), linear-gradient(to top right, transparent 0%, transparent 49%, var(--backgroundColor) 50%, var(--backgroundColor) 100%), linear-gradient(90deg, rgba(245, 124, 49, 0.5) 4%, rgba(282, 80, 30, 0.3) 50%, var(--backgroundColor) 88%);
      background-position: bottom right, bottom right, bottom left, bottom left;
    }
    a {
      border-bottom: 0;
    }

    .social {
      a {
        margin: 0 10px;
        opacity: .5;
        transition: opacity 0.3s;
        &:hover {
          opacity: 1;
        }
      }
      @media (max-width: 772px) {
        text-align: center;
      }
    }

    .contact {
      text-align: right;
      div {
        svg {
          opacity: 1;
          margin: 0 10px;
          position: relative;
          top: 5px;
        }
        > span {
          opacity: 0.5;
          font-size: 18px;
        }
      }
      @media (max-width: 772px) {
        text-align: center;
      }
    }

  }
</style>